import React, { Component } from 'react';
import { getContracts, updateContract, renewContract, feesCalc, getFixedFee, removeContract } from '../../api/sdps';
import { getPartners, getPartnerContacts } from '../../api/partner';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import 'bootstrap/js/dist/modal'
import NumberInput from '../../components/NumberInput';

import LocalizedStrings from '../Localization';
import Loader from '../Loader';
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment-timezone'

import 'moment/locale/nl-be';

moment.tz.setDefault('Europe/Brussels')


class Contracts extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            selectedContract: undefined,
            selection: '',
            oldFixedFee: 0,
            showDetails: false,
            edit: false,
            renew: false,
            loading: false,
            isModalVisible: false,
            contractToRemove: null,
            isResultModalVisible: false,
            resultMessage: ''
        }

        this.allInjection = {
            label: "allInjection",
            all: true,
            type_sdp: "injection",
            type: "injection_hourly",
            signatureDate: undefined,
            startDate: undefined,
            endDate: undefined,
            GOprice: undefined,
            partnerFee: undefined,
            product_id: undefined,
            partner_id: undefined,
            partner_contact_id: undefined,
            partner: {},
            specialCase: 0,
            comment: undefined,
            technology: undefined
        }

        this.allOfftake = {
            label: "allOfftake",
            all: true,
            type_sdp: "offtake",
            type: "offtake_hourly",
            signatureDate: undefined,
            startDate: undefined,
            endDate: undefined,
            balancingFee: undefined,
            minPlatformFee: undefined,
            product_id: undefined,
            priceGSC: undefined,
            priceWKC: undefined,
            partnerFee: undefined,
            partner_id: undefined,
            partner_contact_id: undefined,
            partner: {},
            specialCase: 0,
            comment: undefined
        }

        this.state = {
            ...this.initialState,
            lang: this.props.lang
        }

        this.onSelect = this.onSelect.bind(this);
        this.toggleState = this.toggleState.bind(this);
        this.updatePartners = this.updatePartners.bind(this);
        this.updatePartnerContacts = this.updatePartnerContacts.bind(this);
        this.onSignatureDateChange = this.onSignatureDateChange.bind(this);
        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.clearSignatureDate = this.clearSignatureDate.bind(this);
        this.clearStartDate = this.clearStartDate.bind(this);
        this.clearEndDate = this.clearEndDate.bind(this);
        this.onSpecialCase = this.onSpecialCase.bind(this);
        this.onCommentTyped = this.onCommentTyped.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.partnerFeeCalc = this.partnerFeeCalc.bind(this);
        this.afnFeesCalc = this.afnFeesCalc.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.generateForm = this.generateForm.bind(this);

        this.strings = LocalizedStrings({
            en: {
                loading: "Loading contracts...",
                moreDetails: "More details",
                lessDetails: "Less details",
                fixedFee: "Fixed fee",
                minFixedFee: "Minimum fixed fee is",
                noContracts: "There are no SDPs for selected company.",
                noContractsSelected: "No contract selected.",
                solarImbalanceIndex: "Solar Imbalance Index",
                windImbalanceIndex: "Wind Imbalance Index",
                minPlatformFee: "Min. platform fee",
                id: "Contract ID",
                installation_id: "Installation ID",
                silentRenewalDuration: "Silent renewal duration",
                degressRight: "Degress right",
                dutiesExempt: "Duties exempt",
                nethunt_id: "Nethunt ID",
                minCancelDuration: "Min. cancel duration",
                agreementFilename: "Agreement",
                activeCommission: "Active commission",
                status: "Status",
                ean: "EAN",
                contracts: "Contracts",
                priceGSC: "Price GSC",
                priceWKC: "Price WKC",
                partner: "Contract partner",
                partnerContact: "Contract partner contact",
                edit: "Edit",
                remove: "Remove Contract",
                renew: "Renew",
                partnerFeeMessage: "Partner fee is calculated based on the fixed fee",
                generateContract: "Generate contract",
                balancingFee: "Platform fee",
                signatureDate: "Signature date",
                startDate: "Start date",
                endDate: "End date",
                endDateNotIncluded: "The contract ends on",
                endDateNotIncluded2: "selected date",
                endDateNotIncluded3: ", not including that date.",
                specialCase: "Special case contract",
                comment: "Comment",
                GOprice: "GO price",
                defaultGOprice: "The default GO price is",
                note: "NOTE:",
                partnerFee: "Partner fee",
                update: "Update",
                cancel: "Cancel",
                clear: "Clear",
                partnerFeeNote: "Partner fee is calculated based on the platform fee.",
                currBalancingFeeNote: "Current minimum platform fee is",
                newBalancingFeeNote: "Newly calculated platform fee is",
                editContract: "Edit the contract parameters:",
                contractParams: "Contract parameters:",
                sdpType: "SDP type",
                periodType: "Period type",
                adminFields: "This fields are visible and can be set only by administrators",
                if: "If",
                partnerUser: "partner",
                adminitrator: "adminitrator",
                user: "common user",
                partnerInfo1: "The partner and partner contact for one SDP are set depending on the logged in user:",
                partnerInfo2: "adds an SDP then contract will be linked with that partner company and partner user that added the SDP.",
                partnerInfo3: "adds an SDP the partner will default to Yuso.",
                partnerInfo4: "Only administrators have right to change the partner and partner contact.",
                partnerInfo5: "adds an SDP then partner will always default to Yuso.",
                updateContractInfo: "This action will update the selected contract's parameters. To renew the contract for this installation please choose the RENEW option.",
                updateAllContractInfo: "This action will update all contracts parameters.",
                renewContractInfo: "This action will create a new contract for the selected installation. To edit(update) the current contract please choose the EDIT option.",
                renewAllContractInfo: "This action will create new contracts for all SDPs.",
                errors: "These errors occurred:",
                errorRequired: "Missing required field",
                editError: "Error while updating the contract parameters. Please try again.",
                contractEdited: "Contract parameters were successfully updated!",
                renewError: "Error while renewing the contract. Please try again.",
                contractRenewed: "Contract was successfully renewed!",
                noContract: "No contract",
                signed: "Contract signed",
                unsigned: "Contract not signed",
                allInjection: "ALL INJECTION SDPs",
                allOfftake: "ALL OFFTAKE SDPs",
                noContractInfo: "There are no contract records for selected SDP.",
                allInfo_injection: "Edit or create new contracts for all injection SDPs.",
                allInfo_offtake: "Edit or create new contracts for all offtake SDPs.",
                product: "Product",
                removeError: "Error while removing the contract. Please try again.",
                contractRemoved: "Contract was successfully removed!",
                confirmRemoval: "Are you sure you want to remove this selected",
                confirm: "Confirm"
            },
            nl: {
                loading: "Contracten laden ...",
                moreDetails: "Meer details",
                lessDetails: "Minder details",
                noContracts: "Er zijn geen SDP's voor het geselecteerde bedrijf.",
                minFixedFee: "Minimum vaste vergoeding bedraagt",
                fixedFee: "Vaste vergoeding",
                solarImbalanceIndex: "Solar Onbalans Index",
                windImbalanceIndex: "Wind Onbalans index",
                partnerFeeMessage: "Partnervergoeding wordt berekend op basis van de vaste vergoeding",
                noContractsSelected: "Geen contract geselecteerd.",
                minPlatformFee: "Min. platformkosten",
                id: "Contract ID",
                installation_id: "Installatie ID",
                silentRenewalDuration: "Stille verlengingsduur",
                degressRight: "Graden goed",
                dutiesExempt: "Vrijstelling van taken",
                nethunt_id: "Nethunt ID",
                minCancelDuration: "Min. Annuleringsduur",
                agreementFilename: "Overeenkomst",
                activeCommission: "Actieve commissie",
                status: "Toestand",
                ean: "EAN",
                contracts: "Contracten",
                priceGSC: "Prijs GSC",
                priceWKC: "Prijs WKC",
                partner: "Contractpartner",
                partnerContact: "Contractpartner contact",
                edit: "Bewerk",
                renew: "Vernieuwen",
                generateContract: "Contract genereren",
                balancingFee: "Platformvergoeding",
                signatureDate: "Datum handtekening",
                startDate: "Startdatum",
                endDate: "Einddatum",
                endDateNotIncluded: "Het contract eindigt op",
                endDateNotIncluded2: "geselecteerde datum",
                endDateNotIncluded3: ", exclusief die datum.",
                specialCase: "Speciaal geval contract",
                comment: "Commentaar",
                GOprice: "Go prijs",
                defaultGOprice: "Het standaard GO-prijs is",
                note: "NOTITIE:",
                partnerFee: "Partner commissie",
                update: "Bijwerken",
                cancel: "Annuleren",
                clear: "Wis",
                partnerFeeNote: "Partner commissie worden berekend op basis van de platformvergoeding.",
                currBalancingFeeNote: "De huidige minimale platformvergoeding is",
                newBalancingFeeNote: "Nieuw berekende platformvergoeding is",
                editContract: "Bewerk de contractparameters:",
                contractParams: "Contractparameters:",
                sdpType: "SDP type",
                periodType: "Type periode",
                adminFields: "Deze velden zijn zichtbaar en kunnen alleen door beheerders worden ingesteld",
                if: "Als",
                partnerUser: "partner",
                adminitrator: "beheerder",
                user: "gemeenschappelijke gebruiker",
                partnerInfo1: "De partner en het partnercontact voor één SDP worden ingesteld afhankelijk van de ingelogde gebruiker:",
                partnerInfo2: "voegt een SDP toe, dan wordt het contract gekoppeld aan dat partnerbedrijf en de partnergebruiker die de SDP heeft toegevoegd.",
                partnerInfo3: "voegt een SDP toe, de partner zal standaard Yuso gebruiken.",
                partnerInfo4: "Alleen beheerders hebben het recht om de partner en het partnercontact te wijzigen.",
                partnerInfo5: "voegt een SDP toe, dan zal de partner altijd standaard Yuso gebruiken.",
                updateContractInfo: "Met deze actie worden de parameters van het geselecteerde contract bijgewerkt. Kies de optie VERNIEUWEN om het contract voor deze installatie te verlengen.",
                renewContractInfo: "",
                errors: "Deze fouten zijn opgetreden:",
                errorRequired: "Ontbrekende vereiste veld",
                editError: "Fout bij het bijwerken van de contractparameters. Probeer het opnieuw.",
                contractEdited: "De contractparameters zijn succesvol bijgewerkt!",
                renewError: "Fout bij het verlengen van het contract. Probeer het opnieuw.",
                contractRenewed: "Contract is succesvol verlengd!",
                noContract: "Geen contract",
                signed: "Contract ondertekend",
                unsigned: "Contract niet getekend",
                allInjection: "ALLE INJECTIE SDPs",
                allOfftake: "ALLE AFNAME SDPs",
                noContractInfo: "Er zijn geen contractrecords voor geselecteerde SDP.",
                allInfo_injection: "Bewerk of maak nieuwe contracten voor alle injectie-SDP's.",
                allInfo_offtake: "Bewerk of maak nieuwe contracten voor alle afname-SDP's.",
                product: "Product",
                remove: "Verwijder contract",
                removeError: "Fout bij het verwijderen van het contract. Probeer het opnieuw.",
                contractRemoved: "Contract is succesvol verwijderd!",
                confirmRemoval: "Weet je zeker dat je deze selectie wilt verwijderen",
                confirm: "Confirm"
            }
        }, this.props.lang);
    }

    componentDidMount() {
        this.getContracts()
        this.updatePartners()
    }


    getContracts(prevSelection) {
        if (!this.props.selectedCompany) return;

        getContracts(this.props.selectedCompany.company_id, (err, res) => {
            if (err)
                return window.reportError.send(err);

            let selectedContract = undefined,
                selection = ""

            if (prevSelection && !prevSelection.all) {
                selectedContract = res.find(x => x.id === prevSelection.id)
                selectedContract.partner = this.state.partners.find(x => x.id === selectedContract.partner_id)
                selectedContract.imbCostPct = selectedContract.imbCostPct || null
                selectedContract.imbCostValue = selectedContract.imbCostPct * 100
                selectedContract.windImbCostPct = selectedContract.windImbCostPct || null
                selectedContract.windImbCostValue = selectedContract.windImbCostPct * 100 || null
                selection = `${selectedContract.id}-${selectedContract.ean}-${selectedContract.type}`
            }

            let sdpsContracts = res.sort((x, y) => (x.hasContract < y.hasContract || x.ean.startsWith('EANTMP') > y.ean.startsWith('EANTMP') ? 1 : -1))

            if (this.props.selectedCompany.role === "admin") {
                const inj = sdpsContracts.filter(x => x.type_sdp === "injection").length > 1,
                    afn = sdpsContracts.filter(x => x.type_sdp === "offtake").length > 1

                if (inj || afn)
                    sdpsContracts.unshift({ all: true, divider: true })
                if (inj)
                    sdpsContracts.unshift(this.allInjection)
                if (afn)
                    sdpsContracts.unshift(this.allOfftake)
            }

            this.setState({
                sdpsContracts,
                selectedContract,

                selection,
                edit: false,
                renew: false
            }, () => {
                if (prevSelection && !prevSelection.all)
                    this.updatePartnerContacts(selectedContract.partner_id, selectedContract.partner_contact_id)
            });
        });
    }

    onSelect(selection) {
        if (selection.length) {
            let contract = selection[0]
            contract.partner = this.state.partners.find(x => x.id === contract.partner_id) || {}

            this.setState({
                selection: `${contract.id}-${contract.ean}-${contract.type}`,
                selectedContract: {
                    ...contract,
                    imbCostPct: contract.imbCostPct || null,
                    imbCostValue: contract.imbCostPct ? contract.imbCostPct * 100 : null,
                    windImbCostPct: contract.windImbCostPct || null,
                    windImbCostValue: contract.windImbCostPct ? contract.windImbCostPct * 100 : null,
                    technology: contract.technology,
                    oldPartnerId: contract.partner.id,
                    oldPartnerFee: contract.partnerFee,
                    product_id: contract.product_id ? contract.product_id : 1,
                },
                defaultGOprice: 1,
                edit: false,
                renew: false,
                partnerContacts: []
            }, () => {
                if (contract.hasContract)
                    this.updatePartnerContacts(contract.partner_id, contract.partner_contact_id)
            })
        }
        else
            this.setState({ ...this.initialState })
    }

    toggleState(e) {
        let showDetails = e.target.id === "showDetails" ? !this.state.showDetails : this.state.showDetails,
            edit = e.target.id === "edit" ? !this.state.edit : false,
            renew = e.target.id === "renew" ? !this.state.renew : false,
            selectedContract = this.state.selectedContract

        if (!this.state.selectedContract.all) {
            selectedContract = e.target.id === "renew"
                ? {
                    installation_id: this.state.selectedContract.installation_id || this.state.selectedContract.i_id,
                    type_sdp: this.state.selectedContract.type_sdp,
                    partner_id: this.state.selectedContract.partner_id || '',
                    partner: this.state.selectedContract.partner || '',
                    partner_contact_id: this.state.selectedContract.partner_contact_id || '',
                    partnerContact: this.state.selectedContract.partnerContact || '',
                    id: this.state.selectedContract.id || '',
                    partnerFee: this.state.selectedContract.partner.activeCommission ? this.state.selectedContract.partner.defaultPartnerFee : null,
                    technology: this.state.selectedContract.technology ? this.state.selectedContract.technology : '',
                    imbCostPct: this.state.selectedContract.imbCostPct || null,
                    imbCostValue: this.state.selectedContract.imbCostValue || null,
                    fixedFee: this.state.selectedContract.minFixedFee || 5,
                    windImbCostPct: this.state.selectedContract.windImbCostPct || null,
                    windImbCostValue: this.state.selectedContract.windImbCostValue || null,
                    product_id: this.state.selectedContract.product_id || null
                }
                : this.state.selectedContract

            //Reset selectedContract on cancel
            if ((e.target.id === "edit" && !edit) || (e.target.id === "renew" && !renew)) {
                selectedContract = this.state.sdpsContracts.find(x => x.id === this.state.selectedContract.id)
                selectedContract.imbCostPct = this.state.selectedContract.imbCostPct || null
                selectedContract.imbCostValue = selectedContract.imbCostPct * 100 || null
                selectedContract.windImbCostPct = this.state.selectedContract.windImbCostPct || null
                selectedContract.windImbCostValue = selectedContract.windImbCostPct * 100 || null
                selectedContract.oldPartnerId = this.state.selectedContract.partner.id
                selectedContract.oldPartnerFee = this.state.selectedContract.partnerFee
                selectedContract.partnerContact = this.state.selectedContract.partnerContact
            }

        }

        this.setState({ showDetails, edit, renew, selectedContract, errors: false }, () => {
            if ((edit || renew)) {
                feesCalc(
                    {
                        type: this.state.selectedContract.type_sdp,
                        company_id: this.props.selectedCompany.company_id
                    },
                    (err, res) => {
                        if (err)
                            return window.reportError.send(err);

                        this.setState({
                            selectedContract: {
                                ...this.state.selectedContract,
                                minFixedFee: res.minFixedFee,
                                minPlatformFee: res.balancing_fee,
                                balancingFee: !this.state.selectedContract.all || this.state.renew ? (this.state.edit ? this.state.selectedContract.balancingFee : res.balancing_fee) : undefined
                            }
                        })
                    }
                )
            }
        })
    }

    updatePartners() {
        getPartners((err, res) => {
            if (err)
                return window.reportError.send(err);

            this.setState({ partners: res })
        })
    }

    updatePartnerContacts(partner_id, partner_contact_id) {
        getPartnerContacts(partner_id, (err, res) => {
            if (err)
                return window.reportError.send(err);

            this.setState({
                partnerContacts: res,
                selectedContract: {
                    ...this.state.selectedContract,
                    partnerContact: partner_contact_id ? res.find(x => x.id === partner_contact_id) : res[0],
                    partner_contact_id: partner_contact_id ? partner_contact_id : res[0].id
                }
            })
        })
    }

    onSignatureDateChange(signatureDate) {
        this.setState({ selectedContract: { ...this.state.selectedContract, signatureDate: moment(signatureDate).format('YYYY-MM-DD') } });
    }

    onStartDateChange(startDate) {
        this.setState({ selectedContract: { ...this.state.selectedContract, startDate: moment(startDate).format('YYYY-MM-DD') } });
    }

    onEndDateChange(endDate) {
        this.setState({ selectedContract: { ...this.state.selectedContract, endDate: moment(endDate).format('YYYY-MM-DD') } })
    }

    clearSignatureDate() {
        this.setState({ selectedContract: { ...this.state.selectedContract, signatureDate: null } })
    }

    clearStartDate() {
        this.setState({ selectedContract: { ...this.state.selectedContract, startDate: null } })
    }

    clearEndDate() {
        this.setState({ selectedContract: { ...this.state.selectedContract, endDate: null } })
    }

    onSpecialCase(e) {
        this.setState({ selectedContract: { ...this.state.selectedContract, specialCase: e.target.checked, comment: null } });
    }

    onCommentTyped(e) {
        this.setState({ selectedContract: { ...this.state.selectedContract, comment: e.target.value } })
    }

    onFieldChange(e) {
        const value = e.target.value;

        let parsedValue;
        if (!isNaN(parseFloat(value))) {
            parsedValue = parseFloat(value);
        } else {
            parsedValue = '';
        }

        switch (e.target.name) {
            case "fixedFee":
                this.setState({
                    selectedContract: { ...this.state.selectedContract, fixedFee: e.target.value }
                }, () => {
                    this.partnerFeeCalc()
                })
                break;
            case "partnerFee":
                this.setState({ selectedContract: { ...this.state.selectedContract, partnerFee: e.target.value } })
                break;
            case "imbalanceIndex":
                this.setState({ selectedContract: { ...this.state.selectedContract, imbCostValue: e.target.value.trim() ? parseInt(e.target.value) : '' } })
                break;
            case "windImbalanceIndex":
                this.setState({ selectedContract: { ...this.state.selectedContract, windImbCostValue: e.target.value.trim() ? parseInt(e.target.value) : '' } })
                break;
            case "platform_fee":
                this.setState({
                    selectedContract: { ...this.state.selectedContract, balancingFee: e.target.value }
                }, () => {
                    this.partnerFeeCalc()
                })
                break;
            case "priceGSC":
                this.setState({
                    selectedContract: {
                        ...this.state.selectedContract,
                        priceGSC: parsedValue
                    }
                });
                break;
            case "priceWKC":
                this.setState({ selectedContract: { ...this.state.selectedContract, priceWKC: e.target.value } })
                break;

            case "GOprice":
                this.setState({ selectedContract: { ...this.state.selectedContract, GOprice: e.target.value } })
                break;
            case "partner":
                let partner_id = parseInt(e.target.value)
                this.setState({
                    selectedContract: {
                        ...this.state.selectedContract,
                        partner: this.state.partners.find(x => x.id === partner_id),
                        partner_id
                    }
                }, () => {
                    this.updatePartnerContacts(partner_id)
                    this.partnerFeeCalc()
                })
                break;

            case "partner_contact":
                const partnerContact = this.state.partnerContacts.find(x => x.id === parseInt(e.target.value))
                this.setState({
                    selectedContract: {
                        ...this.state.selectedContract,
                        partnerContact,
                        partner_contact_id: partnerContact.id
                    }
                })
                break;
            case "product_id":
                this.setState({ selectedContract: { ...this.state.selectedContract, product_id: e.target.value } })
                break;

            default:
                break;
        }
    }

    partnerFeeCalc(partnerFee) {
        if (this.state.selectedContract.type_sdp === "injection") {
            getFixedFee(
                this.props.selectedCompany.company_id,
                {
                    activeCommission: this.state.selectedContract.partner.activeCommission,
                    defaultPartnerFee: this.state.selectedContract.partner.activeCommission ? this.state.selectedContract.partner.defaultPartnerFee : null,
                    fixedFee: this.state.selectedContract.fixedFee,
                    type: this.state.selectedContract.type_sdp
                },
                (err, resFixedFee) => {
                    if (err) {
                        return window.reportError.send(err);
                    }
                    this.setState({
                        selectedContract: {
                            ...this.state.selectedContract,
                            minFixedFee: resFixedFee.minFixedFee,
                            fixedFee: resFixedFee ? resFixedFee.fixedFee : this.state.selectedContract.fix,
                            partnerFee: resFixedFee.partnerFee
                        }
                    })
                }
            );
        } else {
            this.afnFeesCalc();
        }
    }

    afnFeesCalc() {

        feesCalc(
            {
                fee: 'partnerFee',
                type: this.state.selectedContract.type_sdp,
                company_id: this.props.selectedCompany.company_id,
                defaultPartnerFee: this.state.selectedContract.partner.activeCommission ? this.state.selectedContract.partner.defaultPartnerFee : null,
                balancingFee: this.state.selectedContract.balancingFee,
            },
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    selectedContract: {
                        ...this.state.selectedContract,
                        balancingFee: res ? res.balancing_fee : this.state.selectedContract.balancingFee,
                        minPlatformFee: res.min_balancing_fee,
                        partnerFee: this.state.selectedContract.partner.activeCommission ? res.partner_fee : this.state.selectedContract.partnerFee
                    }
                })
            }
        );
    }

    handleFocus = (event) => {
        if (event.target.type === 'number') {
            event.target.addEventListener('wheel', this.handleWheel, { passive: false });
        }
    };

    handleBlur = (event) => {
        if (event.target.type === 'number') {
            event.target.removeEventListener('wheel', this.handleWheel);
        }
    };

    handleWheel = (event) => {
        event.preventDefault();
    };

    showRemoveModal = (event) => {
        event.preventDefault();
        this.setState({
            isModalVisible: true,
            contractToRemove: this.state.selectedContract
        });
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            contractToRemove: null,
        });
    };

    handleConfirm = () => {
        this.setState({ isModalVisible: false }, () => {
            this.handleRemove(this.state.contractToRemove);
        });
    };

    handleRemove = (selectedContract) => {
        const { selectedCompany } = this.props;
        if (!selectedCompany || !selectedContract) return;

        const { company_id } = selectedCompany;
        const { installation_id, type, id } = selectedContract;

        this.setState({ loading: true });

        removeContract(company_id, installation_id, type, id, (err, res) => {
            if (err) {
                window.reportError.send(err);
                this.setState({
                    errors: [this.strings.removeError],
                    loading: false,
                    resultMessage: 'An error occurred while removing the selected contract. Please try again.',
                    isResultModalVisible: true,
                });
                return;
            }

            if (res.error) {
                this.setState({
                    errors: [this.strings.removeError],
                    loading: false,
                    resultMessage: 'An error occurred while removing the selected contract. Please try again.',
                    isResultModalVisible: true,
                });
            } else if (res.status === 'OK') {
                this.setState({
                    info: this.strings.contractRemoved,
                    loading: false,
                    selectedContract: null, // Clear the selected contract
                    resultMessage: <p>The selected contract with installation_id <strong>{selectedContract.installation_id}</strong> was successfully removed.</p>,
                    isResultModalVisible: true,
                }, () => {
                    setTimeout(() => {
                        this.setState({ info: undefined }, () => this.getContracts());
                    }, 3000);
                });
            }
        });
    };

    closeResultModal = () => {
        this.setState({ isResultModalVisible: false, resultMessage: '' });
    };

    generateForm(state) {
        return <form onSubmit={this.onSubmit} id={state} noValidate>
            {
                this.props.selectedCompany.role === 'admin' && state === 'edit' ?
                    <div className="form-group row">
                        <label htmlFor="signature_date" className="col-sm-4 col-form-label">{this.strings.signatureDate}</label>
                        <div className="col-sm-8">
                            <div className="input-group mb-8 d-flex flex-nowrap">
                                <DayPickerInput
                                    value={this.state.selectedContract.signatureDate ? moment(this.state.selectedContract.signatureDate, 'YYYY-MM-DD').toDate() : ''}
                                    format="DD-MM-YYYY"
                                    placeholder="DD-MM-YYYY"
                                    formatDate={(date, format) => { return moment(date).format(format) }}
                                    inputProps={{ className: 'form-control', readOnly: 'readonly', id: 'signature_date', name: 'signature_date' }}
                                    dayPickerProps={{
                                        selectedDays: this.state.selectedContract.signatureDate ? moment(this.state.selectedContract.signatureDate, 'YYYY-MM-DD').toDate() : null,
                                        localeUtils: MomentLocaleUtils,
                                        locale: this.props.lang,
                                    }}
                                    onDayChange={this.onSignatureDateChange}
                                />
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-secondary" onClick={this.clearSignatureDate}>{this.strings.clear}&nbsp;&nbsp;&nbsp;&nbsp;</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }

            <div className="form-group row">
                <label htmlFor="start_date" className="col-sm-4 col-form-label">{this.strings.startDate}</label>
                <div className="col-sm-8">
                    <div className="input-group mb-8 d-flex flex-nowrap">
                        <DayPickerInput
                            value={this.state.selectedContract.startDate ? moment(this.state.selectedContract.startDate, 'YYYY-MM-DD').toDate() : ''}
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            formatDate={(date, format) => { return moment(date).format(format) }}
                            inputProps={{ className: 'form-control', required: this.state.selectedContract.all && !this.state.renew ? '' : 'required', readOnly: 'readonly', id: 'start_date', name: 'start_date' }}
                            dayPickerProps={{
                                selectedDays: this.state.selectedContract.startDate ? moment(this.state.selectedContract.startDate).toDate() : null,
                                disabledDays: this.props.selectedCompany.role !== 'admin' && this.state.option === "create" && !this.state.selectedContract.hasContract ? ((date) => moment(date) < moment(this.state.initialMonth)) : null,
                                localeUtils: MomentLocaleUtils,
                                locale: this.props.lang
                            }}
                            onDayChange={this.onStartDateChange}
                        />
                        <div className="input-group-append">
                            <button type="button" className="btn btn-secondary" onClick={this.clearStartDate}>{this.strings.clear}&nbsp;&nbsp;&nbsp;&nbsp;</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="end_date" className="col-sm-4 col-form-label">{this.strings.endDate}</label>
                <div className="col-sm-8">
                    <div className="input-group mb-8 d-flex flex-nowrap">
                        <DayPickerInput
                            value={this.state.selectedContract.endDate ? moment(this.state.selectedContract.endDate, 'YYYY-MM-DD').toDate() : ''}
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            formatDate={(date, format) => { return moment(date).format(format) }}
                            inputProps={{ className: 'form-control', readOnly: 'readonly', id: 'end_date', name: 'end_date' }}
                            dayPickerProps={{
                                selectedDays: this.state.selectedContract.endDate ? moment(this.state.selectedContract.endDate).toDate() : null,
                                localeUtils: MomentLocaleUtils,
                                locale: this.props.lang
                            }}
                            onDayChange={this.onEndDateChange}
                        />
                        <div className="input-group-append">
                            <button type="button" className="btn btn-secondary" onClick={this.clearEndDate}>{this.strings.clear}&nbsp;&nbsp;&nbsp;&nbsp;</button>
                        </div>
                    </div>
                    <small className="form-text text-muted"><strong>{this.strings.note}</strong> {this.strings.endDateNotIncluded} {this.state.selectedContract.endDate || this.strings.endDateNotIncluded2}{this.strings.endDateNotIncluded3}</small>
                </div>
            </div>
            {
                this.state.selectedContract.type_sdp === "injection" ?
                    <div className="form-group row">
                        <label htmlFor="fixedFee" className="col-sm-4 col-form-label">{this.strings.fixedFee}</label>
                        <div className="col-sm-8">
                            <div className="input-group d-flex flex-nowrap">
                                <NumberInput className="form-control" type="number" id="fixedFee" name="fixedFee" min={this.props.selectedCompany.role !== 'admin' ? this.state.oldFixedFee : 10}
                                    value={this.state.selectedContract.fixedFee}
                                    onChange={this.onFieldChange}
                                />
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-primary" disabled>€/MWh</button>
                                </div>
                            </div>
                            {/* <small className="form-text text-muted"><strong>{this.strings.note}</strong> {this.strings.fixedFee} {this.state.selectedContract.fixedFee} €/MWh</small> */}
                            <small className="form-text text-danger"><strong>{this.strings.note}</strong> {this.strings.minFixedFee} {this.state.selectedContract.minFixedFee} €/MWh</small>
                        </div>
                    </div>
                    : this.state.selectedContract.type_sdp === "offtake" ?
                        <div className="form-group row">
                            <label htmlFor="platform_fee" className="col-sm-4 col-form-label">{this.strings.balancingFee}</label>
                            <div className="col-sm-8">
                                <div className="input-group d-flex flex-nowrap">
                                    <NumberInput className="form-control" type="number" id="platform_fee" name="platform_fee" min={this.props.selectedCompany.role !== 'admin' ? 10 : 0}
                                        value={this.state.selectedContract.balancingFee}
                                        onChange={this.onFieldChange}
                                    />
                                    <div className="input-group-append">
                                        <button type="button" className="btn btn-primary" disabled>€/MWh</button>
                                    </div>
                                </div>
                                {this.state.selectedContract.minPlatformFee ? <small className="form-text text-danger"><strong>{this.strings.note}</strong> {this.strings.currBalancingFeeNote} {this.state.selectedContract.minPlatformFee} €/MWh</small> : null}
                            </div>
                        </div>
                        : null
            }
            {
                this.state.selectedContract.partner.activeCommission ?
                    <div className="form-group row">
                        <label htmlFor="partnerFee" className="col-sm-4 col-form-label">Partner fee</label>
                        <div className="col-sm-8">
                            <div className="input-group d-flex flex-nowrap">
                                <NumberInput className="form-control" type="number" id="partnerFee" name="partnerFee" min="0"
                                    value={this.state.selectedContract.partnerFee}
                                    onChange={this.onFieldChange}
                                    disabled={this.props.selectedCompany.role !== 'admin'}
                                />
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-primary" disabled>€/MWh</button>
                                </div>
                            </div>
                            <small className="form-text text-muted"><strong>{this.strings.note}</strong> {this.strings.partnerFeeMessage}</small>
                        </div>
                    </div>
                    : null
            }

            {
                state === 'renew' ?
                    this.state.selectedContract.type_sdp === "injection" ?
                        <div className="form-group row required required-select">
                            <label className="col-sm-3 col-form-label">{this.strings.product}</label>
                            <div className="col-sm">
                                <select
                                    className="form-control"
                                    id="product_id"
                                    name="product_id"
                                    value={this.state.selectedContract.product_id}
                                    onChange={this.onFieldChange}
                                    required
                                >
                                    <option value="1">YUSO Dynamic Injection Solar</option>
                                </select>
                            </div>
                        </div>
                        :
                        <div className="form-group row required required-select">
                            <label className="col-sm-3 col-form-label">{this.strings.product}</label>
                            <div className="col-sm">
                                <select className="form-control" id="product_id" name="product_id" defaultValue={this.state.selectedContract.product_id} onChange={this.onFieldChange} required>
                                    <option value="1" key="YusoDynamicGreen">YUSO Dynamic Green</option>
                                    <option value="2" key="YusoDynamicGreenLocal">YUSO Dynamic Green Local</option>
                                </select>
                            </div>
                        </div>
                    : null
            }

            {
                this.props.selectedCompany.role === 'admin' ?
                    <div className="bg-light border border-dark pt-3 px-3 mb-3">
                        <h5 className="mb-3">{this.strings.adminFields}:</h5>

                        {
                            this.state.selectedContract.type_sdp === "offtake" ?
                                [
                                    <div key={1} className="form-group row">
                                        <label htmlFor="priceGSC" className="col-sm-4 col-form-label">{this.strings.priceGSC}</label>
                                        <div className="col-sm-8">
                                            <NumberInput className="form-control" type="number" name="priceGSC" min="0" value={this.state.selectedContract.priceGSC} onChange={this.onFieldChange}></NumberInput>
                                        </div>
                                    </div>,
                                    <div key={2} className="form-group row">
                                        <label htmlFor="priceWKC" className="col-sm-4 col-form-label">{this.strings.priceWKC}</label>
                                        <div className="col-sm-8">
                                            <NumberInput className="form-control" type="number" name="priceWKC" min="0" value={this.state.selectedContract.priceWKC} onChange={this.onFieldChange} />
                                        </div>
                                    </div>
                                ]
                                : this.state.selectedContract.type_sdp === 'injection' ?
                                    <div key={3} className="form-group row">
                                        <label htmlFor="GOprice" className="col-sm-4 col-form-label">{this.strings.GOprice}</label>
                                        <div className="col-sm-8">
                                            <NumberInput className="form-control" type="number" name="GOprice" min="0" value={this.state.selectedContract.GOprice} onChange={this.onFieldChange} />
                                            <small className="form-text text-danger"><strong>{this.strings.note}</strong> {this.strings.defaultGOprice} <strong>{this.state.defaultGOprice} EUR.</strong></small>
                                        </div>
                                    </div>
                                    : null
                        }

                        {
                            state === "renew" ?
                                <div className="alert alert-info text-white">
                                    <i className="fas fa-info-circle mr-2"></i> {this.strings.partnerInfo1}
                                    <ul>
                                        <li>{this.strings.if} <strong>{this.strings.partnerUser}</strong> {this.strings.partnerInfo2}</li>
                                        <li>{this.strings.if} <strong>{this.strings.adminitrator}</strong> {this.strings.partnerInfo3} <em>{this.strings.partnerInfo4}</em></li>
                                        <li>{this.strings.if} <strong>{this.strings.user}</strong> {this.strings.partnerInfo5}</li>
                                    </ul>
                                </div> : null
                        }

                        <div className={`form-group row ${this.state.selectedContract.all && !this.state.renew ? '' : 'required required-select'}`}>
                            <label htmlFor="partner" className="col-sm-4 col-form-label">{this.strings.partner}</label>
                            <div className="col-sm-8">
                                <select className="form-control" id="partner_id" name="partner" value={this.state.selectedContract.partner_id || ""} onChange={this.onFieldChange} required={!this.state.selectedContract.all || this.state.renew}>
                                    <option value="" disabled>{this.strings.partnerSelect}</option>
                                    {
                                        this.state.partners.map(partner => <option key={partner.id} value={partner.id}>{partner.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>

                        <div className={`form-group row ${this.state.selectedContract.all && !this.state.renew ? '' : 'required required-select'}`}>
                            <label htmlFor="partner_contact_id" className="col-sm-4 col-form-label">{this.strings.partnerContact}</label>
                            <div className="col-sm-8">
                                <select className="form-control mb-3" id="partner_contact_id" name="partner_contact" value={this.state.selectedContract.partner_contact_id || ""} onChange={this.onFieldChange} required={!this.state.selectedContract.all || this.state.renew}>
                                    {
                                        this.state.partnerContacts ? this.state.partnerContacts.map(contact => <option key={contact.id} value={contact.id}>{contact.fullName}</option>) : null
                                    }
                                </select>
                            </div>
                        </div>
                    </div> :
                    null

            }
            {
                this.state.selectedContract.type_sdp === "injection" && this.state.selectedContract.technology !== null ? (
                    <div>
                        {this.state.selectedContract.technology.includes("Solar") && (
                            <div className="form-group row">
                                <label htmlFor="imbalanceIndex" className="col-sm-4 col-form-label">
                                    {this.strings.solarImbalanceIndex}
                                </label>
                                <div className="col-sm-8">
                                    <div className="input-group d-flex flex-nowrap">
                                        <NumberInput
                                            className="form-control"
                                            type="number"
                                            id="imbalanceIndex"
                                            name="imbalanceIndex"
                                            min="0"
                                            value={this.state.selectedContract.imbCostValue}
                                            onChange={this.onFieldChange}
                                            disabled={this.props.selectedCompany.role === 'partner'}
                                        />
                                        <div className="input-group-append">
                                            <button type="button" className="btn btn-primary" disabled>
                                                %
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {
                            this.state.selectedContract.technology.includes('Wind') && (
                                <div className="form-group row">
                                    <label htmlFor="windImbalanceIndex" className="col-sm-4 col-form-label">
                                        {this.strings.windImbalanceIndex}
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="input-group d-flex flex-nowrap">
                                            <NumberInput
                                                className="form-control"
                                                type="number"
                                                id="windImbalanceIndex"
                                                name="windImbalanceIndex"
                                                min="0"
                                                value={this.state.selectedContract.windImbCostValue}
                                                onChange={this.onFieldChange}
                                                disabled={this.props.selectedCompany.role === 'partner'}
                                            />
                                            <div className="input-group-append">
                                                <button type="button" className="btn btn-primary" disabled>
                                                    %
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                ) : null
            }

            <div className="form-group row mb-3">
                <div className="col-sm">
                    <div className="form-check">
                        <label className="form-check-label">
                            <input className="form-check-input" type="checkbox" name="special_case" checked={this.state.selectedContract.specialCase} value={this.state.selectedContract.specialCase || false} onChange={this.onSpecialCase} />
                            {this.strings.specialCase}
                        </label>
                    </div>
                </div>
            </div>

            {
                this.state.selectedContract.specialCase ?
                    <div className="row mb-3">
                        <div className="col-sm">
                            <textarea className="form-control" placeholder={this.strings.comment} onChange={this.onCommentTyped} value={this.state.selectedContract.comment || ''}></textarea>
                        </div>
                    </div>
                    : null
            }

            {
                <p className="alert alert-info mt-3">
                    <i className="fas fa-info-circle"></i>&nbsp;
                    {
                        state === "edit" ? !this.state.selectedContract.all ? this.strings.updateContractInfo : this.strings.updateAllContractInfo
                            : state === "renew" ? !this.state.selectedContract.all ? this.strings.renewContractInfo : this.strings.renewAllContractInfo
                                : null
                    }
                </p>
            }

            {
                this.state.errors ?
                    <div className="alert alert-warning mb-3 d-block">
                        <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.errors}
                        <ul>
                            {
                                this.state.errors ? this.state.errors.sort().map(error => (<li key={error}>{error}</li>)) : null
                            }
                        </ul>
                    </div>
                    : null
            }

            {this.state.info ? <p className="alert alert-info"><i className="fas fa-info-circle mr-2"></i> {this.state.info}</p> : null}

            <div className="text-right">
                <button type="button" id={state} className="btn btn-secondary mr-2" onClick={this.toggleState} disabled={this.state.loading}>{this.strings.cancel}</button>
                <button type="submit" id={state} className="btn btn-primary" disabled={this.state.loading}><i className={!this.state.loading ? "fas fa-save text-white mr-2" : "fa fa-spinner text-white fa-spin mr-2"}></i>{state === "edit" ? this.strings.update : state === "renew" && !this.state.selectedContract.hasContract ? this.strings.generateContract : this.strings.renew}</button>
            </div>
        </form>
    }

    onSubmit(e) {
        e.preventDefault()

        this.setState({ errors: false, loading: true })

        let form = e.target.elements,
            errors = []

        for (let i = 0; i < form.length; i++)
            if (form[i].required && !form[i].value.trim().length)
                errors.push(`${this.strings.errorRequired} ${form[i].name.split('_').join(' ')}`)

        if (errors.length) {
            this.setState({ errors, loading: false })
            return;
        }

        this.setState({ selectedContract: { ...this.state.selectedContract, imbCostPct: this.state.selectedContract.imbCostValue / 100, windImbCostPct: this.state.selectedContract.windImbCostValue / 100 } }, () => {

            if (e.target.id === 'edit')
                updateContract(this.props.selectedCompany.company_id, this.state.selectedContract,
                    (err, res) => {
                        if (err)
                            return window.reportError.send(err);

                        if (res.error)
                            this.setState({ errors: [this.strings.editError], loading: false })

                        if (res.status === 'OK')
                            this.setState({ info: this.strings.contractEdited, loading: false }, () => {
                                setTimeout(() => {
                                    this.setState({ info: undefined }, () => this.getContracts(this.state.selectedContract))
                                }, 3000);
                            })
                    })

            else if (e.target.id === 'renew')
                renewContract(this.props.selectedCompany.company_id, this.state.selectedContract,
                    (err, res) => {
                        if (err)
                            return window.reportError.send(err);

                        if (res.error)
                            this.setState({ errors: [this.strings.renewError], loading: false })

                        if (res.status === 'OK')
                            this.setState({ info: this.strings.contractRenewed, loading: false }, () => {
                                setTimeout(() => {
                                    this.setState({ info: undefined }, () => this.getContracts({ ...this.state.selectedContract, id: res.id }))
                                }, 3000);
                            })
                    })
        })
    }

    render() {
        const selectedContract = this.state.selectedContract
        return (
            <div className="card mb-4">
                <div className="card-header">
                    {this.strings.contracts}
                </div>
                <div className="card-body">
                    {
                        !this.state.sdpsContracts ?
                            <Loader inline="true">{this.strings.loading}</Loader>
                            :
                            !this.state.sdpsContracts.length ?
                                <p className="alert alert-info mt-3">
                                    <i className="fas fa-info-circle"></i> {this.strings.noContracts}
                                </p>
                                :
                                <div>
                                    <div className="form-group row">
                                        <label htmlFor="contractSelector" className="col-sm-3 col-form-label">{this.strings.contracts}:</label>
                                        <div className="col-sm-9 special-select">
                                            <Typeahead
                                                id="contractSelector"
                                                options={this.state.sdpsContracts}
                                                labelKey={x => !x.label ? `${x.ean} (${x.i_id})` : this.strings[x.label]}
                                                renderMenu={(results, menuProps) => (
                                                    <Menu {...menuProps}>
                                                        {
                                                            results.map((x) => (
                                                                !x.all
                                                                    ? <MenuItem option={x} key={x.ean + "-" + x.id}>
                                                                        {x.ean} ({x.i_id}) <span>{' | ' + x.type_sdp}{x.hasContract ? ` | ${this.strings.startDate}: ${x.startDate}` : (' | ' + this.strings.noContract)}</span>
                                                                    </MenuItem>
                                                                    : x.divider
                                                                        ? <Menu.Divider />
                                                                        : <MenuItem option={x} key={x.label}>{this.strings[x.label]}</MenuItem>
                                                            ))
                                                        }
                                                    </Menu>
                                                )}
                                                onChange={this.onSelect}
                                                filterBy={!this.state.selectedContract ? undefined : () => true}
                                                selected={this.state.ean ? [this.state.ean] : ""}
                                                clearButton
                                            />
                                        </div>
                                    </div>
                                    {
                                        !selectedContract ?
                                            <p className="alert alert-info mt-3">
                                                <i className="fas fa-info-circle"></i> {this.strings.noContractsSelected}
                                            </p>
                                            :
                                            selectedContract.all && !this.state.edit && !this.state.renew ?
                                                <div>
                                                    <div className="alert alert-info text-white">
                                                        <i className="fas fa-info-circle mr-2"></i> {this.strings['allInfo_' + selectedContract.type_sdp]}
                                                    </div>
                                                    <div className="text-right">
                                                        <button type="button" id="edit" className="btn btn-primary mr-2" onClick={this.toggleState}><i className="fas fa-edit text-white mr-2"></i>{this.strings.edit}</button>
                                                        <button type="button" id="renew" className="btn btn-primary" onClick={this.toggleState}><i className="fas fa-redo text-white mr-2"></i>{this.strings.renew}</button>
                                                    </div>
                                                </div>
                                                :
                                                this.state.edit && !(this.props.selectedCompany.role === "partner" && selectedContract.signatureDate !== null) ?
                                                    <div className="mt-3">
                                                        <p>{this.strings.editContract}</p>
                                                        {
                                                            this.generateForm("edit")
                                                        }
                                                    </div>
                                                    : this.state.renew ?
                                                        <div className="mt-3">
                                                            <p>{this.strings.renewContract}</p>
                                                            {
                                                                this.generateForm("renew")
                                                            }
                                                        </div>
                                                        :
                                                        selectedContract.hasContract ?
                                                            <div className="mt-3">
                                                                <p>{this.strings.contractParams}</p>
                                                                <table className="table table-sm table-striped">
                                                                    <tbody>
                                                                        {
                                                                            this.state.showDetails ?
                                                                                [
                                                                                    <tr key={1}>
                                                                                        <td>{this.strings.ean}</td>
                                                                                        <td><strong>{selectedContract.ean}</strong></td>
                                                                                    </tr>,
                                                                                    <tr key={2}>
                                                                                        <td>{this.strings.installation_id}</td>
                                                                                        <td><strong>{selectedContract.installation_id}</strong></td>
                                                                                    </tr>
                                                                                ]
                                                                                : null
                                                                        }
                                                                        <tr>
                                                                            <td>{this.strings.id}</td>
                                                                            <td><strong>{selectedContract.id}</strong></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{this.strings.signatureDate}</td>
                                                                            <td>{selectedContract.signatureDate === null ? <div className="text-muted">NULL</div> : <strong>{selectedContract.signatureDate}</strong>}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{this.strings.startDate}</td>
                                                                            <td>{selectedContract.startDate === null ? <div className="text-muted">NULL</div> : <strong>{selectedContract.startDate}</strong>}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{this.strings.endDate}</td>
                                                                            <td>{selectedContract.endDate === null ? <div className="text-muted">NULL</div> : <div>
                                                                                <strong>{selectedContract.endDate}</strong>
                                                                                <small className="form-text text-muted"><strong>{this.strings.note}</strong> {this.strings.endDateNotIncluded} {selectedContract.endDate}{this.strings.endDateNotIncluded3}</small>
                                                                            </div>}</td>
                                                                        </tr>
                                                                        {
                                                                            selectedContract.type_sdp === "injection" ?
                                                                                [
                                                                                    selectedContract.fixedFee !== null || selectedContract.fixedFee !== 0 ?
                                                                                        <tr>
                                                                                            <td>{this.strings.fixedFee}</td>
                                                                                            <td><strong>{selectedContract.fixedFee}</strong></td>
                                                                                        </tr> : null,
                                                                                    selectedContract.technology && selectedContract.technology.includes("Solar") ?
                                                                                        <tr>
                                                                                            <td>{this.strings.solarImbalanceIndex}</td>
                                                                                            <td><strong>{selectedContract.imbCostValue}</strong></td>
                                                                                        </tr> : null,
                                                                                    selectedContract.technology && selectedContract.technology.includes("Wind") ?
                                                                                        <tr>
                                                                                            <td>{this.strings.windImbalanceIndex}</td>
                                                                                            <td><strong>{selectedContract.windImbCostValue}</strong></td>
                                                                                        </tr> : null,
                                                                                    selectedContract.partner.activeCommission ?
                                                                                        <tr>
                                                                                            <td>{this.strings.partnerFee}</td>
                                                                                            <td><strong>{selectedContract.partnerFee}</strong></td>
                                                                                        </tr> : null,
                                                                                    this.props.selectedCompany.role === 'admin' ? [
                                                                                        <tr key={3}>
                                                                                            <td>{this.strings.GOprice}</td>
                                                                                            <td><strong>{selectedContract.GOprice}</strong></td>
                                                                                        </tr>,
                                                                                        this.state.showDetails ? [
                                                                                            <tr key={4}>
                                                                                                <td>{this.strings.minCancelDuration}</td>
                                                                                                <td><strong>{selectedContract.minCancelDuration.toUpperCase()}</strong></td>
                                                                                            </tr>,
                                                                                            <tr key={5}>
                                                                                                <td>{this.strings.sdpType}</td>
                                                                                                <td><strong>{selectedContract.type_sdp.toUpperCase()}</strong></td>
                                                                                            </tr>,
                                                                                            <tr key={6}>
                                                                                                <td>{this.strings.periodType}</td>
                                                                                                <td><strong>{selectedContract.type_period.toUpperCase()}</strong></td>
                                                                                            </tr>,
                                                                                            selectedContract.specialCase ?
                                                                                                <tr key={7}>
                                                                                                    <td>{this.strings.comment}</td>
                                                                                                    <td><strong>{selectedContract.comment}</strong></td>
                                                                                                </tr>
                                                                                                : null,
                                                                                            <tr key={8}>
                                                                                                <td>{this.strings.agreementFilename}</td>
                                                                                                <td>{selectedContract.agreementFilename ? <strong>{selectedContract.agreementFilename}</strong> : <div className="text-muted">N/A</div>}</td>
                                                                                            </tr>
                                                                                        ]
                                                                                            : null
                                                                                    ]
                                                                                        : [
                                                                                            <tr key={9}>
                                                                                                <td>{this.strings.priceGSC}</td>
                                                                                                <td><strong>{selectedContract.priceGSC}</strong></td>
                                                                                            </tr>,
                                                                                            <tr key={10}>
                                                                                                <td>{this.strings.priceWKC}</td>
                                                                                                <td><strong>{selectedContract.priceWKC}</strong></td>
                                                                                            </tr>
                                                                                        ]
                                                                                ] :
                                                                                selectedContract.type_sdp === "offtake" ?
                                                                                    [
                                                                                        this.props.selectedCompany.role === "admin" ?
                                                                                            <tr key={11}>
                                                                                                <td>{this.strings.silentRenewalDuration}</td>
                                                                                                <td><strong>{selectedContract.silentRenewalDuration.toUpperCase()}</strong></td>
                                                                                            </tr>
                                                                                            : null,
                                                                                        <tr key={12}>
                                                                                            <td>{this.strings.balancingFee}</td>
                                                                                            <td><strong>{selectedContract.balancingFee}</strong></td>
                                                                                        </tr>,
                                                                                        selectedContract.partner.activeCommission ?
                                                                                            <tr key={13}>
                                                                                                <td>{this.strings.partnerFee}</td>
                                                                                                <td><strong>{selectedContract.partnerFee}</strong></td>
                                                                                            </tr> : null
                                                                                    ]
                                                                                    : null
                                                                        }
                                                                        <tr>
                                                                            <td>{this.strings.partner}</td>
                                                                            <td><strong>{selectedContract.partner.name}</strong></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{this.strings.partnerContact}</td>
                                                                            <td><strong>{selectedContract.partnerContact ? `${selectedContract.partnerContact.firstName} ${selectedContract.partnerContact.lastName}` : null}</strong></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{this.strings.status}</td>
                                                                            <td><strong>{selectedContract.status}</strong></td>
                                                                        </tr>
                                                                        {
                                                                            selectedContract.partner.activeCommission ?
                                                                                <tr>
                                                                                    <td>{this.strings.activeCommission}</td>
                                                                                    <td><strong>{selectedContract.partner.activeCommission ? "YES" : "NO"}</strong></td>
                                                                                </tr>
                                                                                : null
                                                                        }
                                                                        {/* {
                                                                        this.props.selectedCompany.role === "admin" ?
                                                                            selectedContract.type_sdp === "injection" ?
                                                                                <tbody>

                                                                                    {
                                                                                        this.state.showDetails ?
                                                                                            <tr>
                                                                                                <td>{this.strings.minCancelDuration}</td>
                                                                                                <td><strong>{selectedContract.minCancelDuration.toUpperCase()}</strong></td>
                                                                                            </tr>
                                                                                            : null
                                                                                    }
                                                                                </tbody>
                                                                                :
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>{this.strings.priceGSC}</td>
                                                                                        <td><strong>{selectedContract.priceGSC}</strong></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>{this.strings.priceWKC}</td>
                                                                                        <td><strong>{selectedContract.priceWKC}</strong></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            : null
                                                                        } */}
                                                                        {/* {
                                                                        this.state.showDetails ?
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{this.strings.sdpType}</td>
                                                                                    <td><strong>{selectedContract.type_sdp.toUpperCase()}</strong></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{this.strings.periodType}</td>
                                                                                    <td><strong>{selectedContract.type_period.toUpperCase()}</strong></td>
                                                                                </tr>
                                                                                {
                                                                                    selectedContract.type_sdp === "offtake" && this.props.selectedCompany.role === "admin" ?
                                                                                        <tr>
                                                                                            <td>{this.strings.silentRenewalDuration}</td>
                                                                                            <td><strong>{selectedContract.silentRenewalDuration.toUpperCase()}</strong></td>
                                                                                        </tr>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    selectedContract.specialCase ?
                                                                                        <tr>
                                                                                            <td>{this.strings.comment}</td>
                                                                                            <td><strong>{selectedContract.comment}</strong></td>
                                                                                        </tr>
                                                                                        : null
                                                                                }
                                                                                <tr>
                                                                                    <td>{this.strings.agreementFilename}</td>
                                                                                    <td>{selectedContract.agreementFilename ? <strong>{selectedContract.agreementFilename}</strong> : <div className="text-muted">N/A</div>}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                            : null
                                                                        } */}
                                                                        <tr>
                                                                            <td className="borderless bg-white text-right" colSpan="2">{this.state.showDetails ? <div className="btn-link d-inline-block" id="showDetails" onClick={this.toggleState}>{this.strings.lessDetails}</div> : <div className="btn-link d-inline-block" id="showDetails" onClick={this.toggleState}>{this.strings.moreDetails}</div>}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div className="text-right">
                                                                    {
                                                                        !(this.props.selectedCompany.role === "partner" && selectedContract.signatureDate !== null)
                                                                            ? <button type="button" id="edit" className="btn btn-primary mr-2" onClick={this.toggleState}><i className="fas fa-edit text-white mr-2"></i>{this.strings.edit}</button>
                                                                            : null
                                                                    }
                                                                    <button type="button" id="renew" className="btn btn-primary" onClick={this.toggleState}><i className="fas fa-redo text-white mr-2"></i>{this.strings.renew}</button>
                                                                    {this.props.selectedCompany.role === "admin" ?
                                                                        <button type="button" id="remove" className="btn btn-danger ml-2" onClick={this.showRemoveModal}><i className="fas fa-close text-white mr-2"></i>{this.strings.remove}</button>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            <div>
                                                                <div className="alert alert-info text-white">
                                                                    <i className="fas fa-info-circle mr-2"></i> {this.strings.noContractInfo}
                                                                </div>
                                                                <button type="button" id="renew" className="btn btn-primary" onClick={this.toggleState}><i className="fas fa-redo text-white mr-2"></i>{this.strings.generateContract}</button>
                                                            </div>
                                    }
                                    {this.state.isModalVisible && (
                                        <div className="modal fade show" id="status-contract" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                            <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-body">
                                                        <p>{`${this.strings.confirmRemoval} contract with installation_id`} <strong>{this.state.contractToRemove ? this.state.contractToRemove.installation_id : ''}</strong> ?</p>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger" onClick={this.handleConfirm}>{this.strings.confirm}</button>
                                                        <button type="button" className="btn btn-secondary" onClick={this.handleCancel}>{this.strings.cancel}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.isResultModalVisible && (
                                        <div className="modal fade show" id="result-modal" tabIndex="-1" role="dialog" aria-labelledby="resultModalLabel" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                            <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-body">
                                                        <p>{this.state.resultMessage}</p>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" onClick={this.closeResultModal}>{this.strings.cancel}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                    }
                </div>
            </div>
        )
    }
}
// const NumberInput = withScrollDisable(Contracts);
export default Contracts;
